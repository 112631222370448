import Vue from "vue";
import store from "@/js/store/index";

const correctDate = {
	getTimeZoneName() {
		const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		return timeZone ? (timeZone === "Europe/Kyiv" ? "Europe/Kiev" : timeZone) : "Etc/UTC";
	},
	getCurrentTimezone() {
		const cu = store.getters.currentUser;
		return cu && cu.zone ? cu.zone : "Etc/UTC";
	},
	correctDate(date, zone = this.getCurrentTimezone()) {
		const d = new Date(date || Date.now());
		return new Date(d.toLocaleString("en-US", { timeZone: zone }));
	},
	d_mmm_yyyy(millis) {
		const date = this.correctDate(millis);
		const dateNum = date.getDate();
		const month = date.toLocaleString("en-US", { month: "short" });
		const year = date.getFullYear();

		return `${dateNum} ${month}, ${year}`;
	},
	dd_mm_yyyy(millis) {
		const date = this.correctDate(millis);
		const dateNum = this.numberZeroPrettyfier(date.getDate());
		const month = this.numberZeroPrettyfier(date.getMonth() + 1);
		const year = date.getFullYear();

		return `${dateNum}.${month}.${year}`;
	},
	www_mmm_dd_yyyy(millis) {
		const date = this.correctDate(millis);

		return date.toDateString();
	},
	d_mmmm(millis) {
		const date = this.correctDate(millis);
		const dateNum = date.getDate();
		const month = date.toLocaleString("en-US", { month: "long" });

		return `${dateNum} ${month}`;
	},
	mmm_dd(millis) {
		const date = this.correctDate(millis);
		const month = date.toLocaleString("en-US", { month: "short" });
		const dateNum = date.getDate();

		return `${month} ${dateNum}`;
	},
	mmm_dd_yyyy(millis) {
		const date = this.correctDate(millis);
		const year = date.getFullYear();

		return `${this.mmm_dd(millis)}, ${year}`;
	},
	mmm_dd_yy(millis) {
		const date = this.correctDate(millis);
		const year = date.getFullYear().toString().substring(2);

		return `${this.mmm_dd(millis)}, ${year}`;
	},
	hh_mm(millis) {
		const date = this.correctDate(millis);
		const hours = this.numberZeroPrettyfier(date.getHours());
		const minutes = this.numberZeroPrettyfier(date.getMinutes());

		return `${hours}:${minutes}`;
	},
	hh_mm_apm(millis) {
		const date = this.correctDate(millis);
		const hours = date.getHours();
		const hoursIn12Format = this.convertTime24to12(hours);
		const minutes = this.numberZeroPrettyfier(date.getMinutes());
		const amp = this.amOrPm(hours);

		return `${hoursIn12Format}:${minutes} ${amp}`;
	},
	yyyy(millis) {
		const date = this.correctDate(millis);
		const year = date.getFullYear();

		return year;
	},
	mm_dd_yyyy(millis, divider = "/") {
		const date = this.correctDate(millis);
		const dateNum = this.numberZeroPrettyfier(date.getDate());
		const month = this.numberZeroPrettyfier(date.getMonth() + 1);
		const year = date.getFullYear();

		return `${month}${divider}${dateNum}${divider}${year}`;
	},
	yyyy_mm_dd_hh_mm_ss(millis) {
		const date = this.correctDate(millis);
		const year = date.getFullYear();
		const month = this.numberZeroPrettyfier(date.getMonth() + 1);
		const dateNum = this.numberZeroPrettyfier(date.getDate());
		const secs = this.numberZeroPrettyfier(date.getSeconds());

		return `${year}-${month}-${dateNum} ${this.hh_mm()}:${secs}.000000`;
	},
	numberZeroPrettyfier(num, withPlus) {
		let res = "";
		if (Math.abs(parseInt(num)) < 10) {
			res = num < 0 ? `-0${Math.abs(num)}` : `0${num}`;
		} else {
			res = num.toString();
		}
		return withPlus && num >= 0 ? "+" + res : res;
	},
	convertTime24to12(time) {
		return this.numberZeroPrettyfier(time % 12 || 12);
	},
	amOrPm(hours) {
		return hours >= 12 ? "pm" : "am";
	},
	getOffset(timeZone = this.getCurrentTimezone(), date = new Date()) {
		const utcDate = new Date(date.toLocaleString("en-US", { timeZone: "UTC" }));
		const tzDate = new Date(date.toLocaleString("en-US", { timeZone }));

		return (tzDate.getTime() - utcDate.getTime()) / 6e4;
	},
	toHoursAndMinutes(totalMinutes = this.getOffset()) {
		let hours = Math.floor(Math.abs(totalMinutes) / 60);
		const minutes = totalMinutes % 60;
		const formattedMinutes = minutes === 0 ? "00" : Math.abs(minutes);
		if (totalMinutes < 0) hours = hours * -1;
		return this.numberZeroPrettyfier(hours, true) + ":" + formattedMinutes;
	}
};

for (let key in correctDate) {
	Vue.prototype["$" + key] = (arg1, arg2) => correctDate[key](arg1, arg2);
}
