var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"la-search",attrs:{"aria-label":"LinkedIn Search panel"}},[_c('SearchValidator',{on:{"statusChange":_vm.onSearchValidated},model:{value:(_vm.link),callback:function ($$v) {_vm.link=$$v},expression:"link"}}),(_vm.linkWithActivelyHiringQuery)?_c('div',{staticClass:"formatted-text"},[_vm._m(0),_c('br')]):_vm._e(),(_vm.$store.getters.signedInToTeammate)?_c('SideAccountWarning'):_vm._e(),_c('transition',{attrs:{"name":"slide"}},[(_vm.validSearch)?_c('div',{staticClass:"leads-count-found"},[_vm._v(" LinkedIn profiles found: "),_c('b',[_vm._v(_vm._s(_vm.numberWithSpaces(_vm.maxSearchValue))),(+_vm.totalLeadsFound === 1000)?[_vm._v("+")]:_vm._e()],2),(_vm.validationResponse.startFrom)?_c('p',{staticClass:"leads-count-found__not-first"},[_vm._v(" The link you've pasted does not come from the first page of search results, therefore all leads from previous pages will be skipped ")]):_vm._e()]):_vm._e()]),_c('transition',{attrs:{"name":"slide"}},[(_vm.totalLeadsFound)?_c('div',{staticClass:"leads-count-settings"},[_c('label',{staticClass:"field__label",attrs:{"for":"leadsCount"}},[_vm._v(" How many users you would like to add to this list? "),_c('span',{staticClass:"field__req",attrs:{"aria-label":"Required"}},[_vm._v("*")]),_c('span',{staticClass:"small"},[_vm._v(" (max: "+_vm._s(_vm.maxSearchValue)+")")])]),_c('div',{staticClass:"flex"},[_c('TextInput',{attrs:{"input-name":"leadsCount","validation":{
						numeric: true,
						required: true,
						max_value: _vm.maxSearchValue,
						min_value: 1
					},"disabled":_vm.allUsers,"inputmode":"numeric","numeric":"","inline":""},model:{value:(_vm.leadsCount),callback:function ($$v) {_vm.leadsCount=$$v},expression:"leadsCount"}}),_c('Checkbox',{attrs:{"check-name":"allUsers"},model:{value:(_vm.allUsers),callback:function ($$v) {_vm.allUsers=$$v},expression:"allUsers"}},[_vm._v(" max ")])],1)]):_vm._e()])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("Important!")]),_vm._v(" Since the search URL contains Premium filters, please make sure leads are filtered on the corresponding LinkedIn account, otherwise, the results might not be accurate ")])
}]

export { render, staticRenderFns }