export default {
	state: {
		userInTeams: [],
		signedInToTeammate: false
	},
	getters: {
		userInTeams: (s) => s.userInTeams,
		userInTeam: (s, g) => !!g.userInTeams.length,
		signedInToTeammate: (s) => s.signedInToTeammate
	},
	mutations: {
		setUserInTeams(state, team) {
			state.userInTeams = team;
		},
		clearUserInTeams(state) {
			state.userInTeams = [];
		},
		setSignedToTeammateValue(state, val) {
			state.signedInToTeammate = val;
		},
		changeMyTeamName(state, res) {
			const myTeam = state.userInTeams.find((team) => team.teamId === res.id);
			myTeam.teamName = res.name;
		}
	},
	actions: {
		async getCurrentUserInTeams(store) {
			const res = await window.request("GET", "/v1/shared/teams/users/current");
			store.commit("setUserInTeams", res.data);
		},
		async getUserInTeamsById(_, userDetailsId) {
			const res = await window.request("GET", `/v1/shared/teams/users/${userDetailsId}`);

			return res.data;
		},
		async createTeam(store, { invitations, name }) {
			try {
				await window.request("POST", "/v1/shared/teams", { invitations, name });
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async editTeam(store, { payload, id }) {
			try {
				const res = await window.request("PATCH", `/v1/shared/teams/${id}`, payload);
				store.commit("changeMyTeamName", res.data);
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async addTeammates(store, payload) {
			try {
				await window.request("POST", "/v1/shared/teams/invitations", payload);
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async getMyTeamInvitations(store) {
			try {
				const res = await window.request("GET", "/v1/shared/teams/my/invitations");

				return res.data;
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async getTeam(store, payload) {
			try {
				if (!store.getters.userInTeam) return { content: [] };
				const res = await window.request("GET", "/v1/shared/teams/users", payload);

				return res.data;
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async getTeamInvitations(store, payload) {
			try {
				if (!store.getters.userInTeam) return { content: [] };
				const res = await window.request("GET", "/v1/shared/teams/invitations", payload);

				return res.data;
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async registerUserInTeam(store, code) {
			try {
				await window.request("POST", "/v1/shared/teams/users", {
					invitationCode: code
				});
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async updateUserInvitation(store, id) {
			try {
				await window.request("PUT", `/v1/shared/teams/invitations/${id}`, { declined: true, id });
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async updateUserInTeam(store, payload) {
			try {
				await window.request("PUT", `/v1/shared/teams/users/${payload.id}`, payload);
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async getMemberByEmail(store, email) {
			try {
				const res = await window.request("GET", `/v1/shared/teams/users/emails/${email}`);

				return res.data;
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async deleteUserInTeam(store, userId) {
			try {
				await window.request("DELETE", `/v1/shared/teams/users/${userId}`);
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async recallInvite(store, id) {
			try {
				await window.request("DELETE", `/v1/shared/teams/invitations/${id}`);
			} catch (err) {
				store.commit("setMessage", err.response.data);
				throw err;
			}
		},
		async getTeamStatistics(store, payload) {
			try {
				const res = await window.request("GET", `/v1/shared/teams/export/statistics`, payload);

				return res;
			} catch (err) {
				store.commit("setRequestErrorMessage", err.response.data);
				throw err;
			}
		},
	}
};
