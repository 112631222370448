export const DESKTOP_SCREEN_WIDTH = 1365;
export const TABLET_SCREEN_WIDTH = 1024;
export const FABLET_SCREEN_WIDTH = 768;
export const MOBILE_SCREEN_WIDTH = 485;

export const PLANS_LIST = [
	"basic-monthly",
	"pro-monthly",
	"advanced-monthly",
	"basic-annual",
	"pro-annual",
	"advanced-annual"
];
export const BEST_PLAN_ID = PLANS_LIST[PLANS_LIST.length - 1];

export const EF_PLANS_LIST = [
	"small-monthly",
	"medium-monthly",
	"large-monthly",
	"extralarge-monthly",
	"small-annual",
	"medium-annual",
	"large-annual",
	"extralarge-annual"
];
export const EF_BEST_PLAN_ID = EF_PLANS_LIST[EF_PLANS_LIST.length - 1];

export const SUBSCRIPTION_STATUSES = ["active", "overdue", "canceled", "deactivated", "paused"];

// I left it here to make TS refactoring easier
export const NOT_EDITABLE_BILLING_EMAIL_STATUSES = ["canceled", "deactivated"];

export const PLANS_PRICES = {
	"basic-monthly": 59,
	"pro-monthly": 79,
	"advanced-monthly": 99,
	"basic-annual": 39,
	"pro-annual": 59,
	"advanced-annual": 79,

	"small-monthly": 39,
	"medium-monthly": 69,
	"large-monthly": 99,
	"extralarge-monthly": 129,
	"small-annual": 29,
	"medium-annual": 49,
	"large-annual": 69,
	"extralarge-annual": 99
};

export const WEEK_DAYS_NAMES_LIST = [
	"SUNDAY",
	"MONDAY",
	"TUESDAY",
	"WEDNESDAY",
	"THURSDAY",
	"FRIDAY",
	"SATURDAY"
];

export const DRIPIFY_BIRTH_DATE = 1546297200000;
